* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: Gilroy-regular;
}

.text-3-5xl {
  font-size: 2rem;
}

.max-w-screen-5xl {
  max-width: 1678px;
}

.bg-voilet-900 {
  background-color: #5c3eb3;
}

.primary-color {
  color: #5c3eb3;
}

.bg-top-talent {
  background: #5c3eb3;
}

.border-primary-color {
  border-color: 1px #5c3eb3;
}

.gredient-color {
  background: linear-gradient(180.37deg, #5c3eb3 .32%, #5c3eb3ef 44.99%, #5c3eb3bd 108.52%, #5c3eb3a2 120.27%, #5c3eb35e 120.29%, #5c3eb300 121.8%, #5c3eb3b1 121.8%, #5c3eb341 121.8%, #5c3eb37e 121.8%);
}

.grdient-navbar {
  background: linear-gradient(180.37deg, #5c3eb3 .32%, #5c3eb2 44.99%);
}

.hero_bg_gradient {
  background: linear-gradient(#7c40df 0%, #331856 100%);
}

.client_logo_heading, .hire-talent-heading {
  color: #232323;
}

.trusted_para:before {
  content: "";
  z-index: 0;
  background: #232323;
  width: 25px;
  height: 1px;
  margin-left: -29px;
  position: absolute;
  top: 32%;
}

.hero {
  text-align: center;
  background-image: url("https://ql-web-images.s3.us-east-2.amazonaws.com/ql-images/clients-logos1.png");
  background-repeat: repeat-x;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-family: Montserrat, sans-serif;
  animation: 30s linear infinite marquee;
  display: flex;
}

@media screen and (width <= 768px) {
  .hero {
    background-image: url("https://ql-web-images.s3.us-east-2.amazonaws.com/ql-images/clients-logos-mobile.png");
    height: 30px;
  }
}

@keyframes marquee {
  0% {
    background-position: 0;
  }

  100% {
    background-position: 100%;
  }
}

.bg-offwhite-100 {
  background: #0505050f;
}

.peach-medium {
  background: #fff1f1;
}

.peach-lightest {
  background: #fff6f1;
}

.contact-negative {
  height: 60rem;
}

.inset-center {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hire-top-talent h1 > span {
  font-family: Gilroy-Bold, sans-serif;
}

#circle {
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
}

#circle:before {
  content: "";
  border: 2px solid #c5ccda;
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
  position: absolute;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.line-base:hover:after {
  content: "";
  background-color: #ff7c7c;
  width: 5rem;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.social-fillup:hover {
  background-color: #5c3eb3;
}

.social-fillup:hover .foot-hover {
  fill: #fff !important;
}

.mnreImage {
  max-width: 120%;
}

.bg-service-work {
  background-image: url("Purple-gradient.3d2f41e4.png");
}

@media screen and (width <= 768px) {
  .mnreImage {
    max-width: 100%;
  }

  .bg-service-work {
    background-image: url("purple-mobile-work.8eee3d46.png");
  }
}

#slider {
  width: 500px;
  height: 400px;
  position: relative;
  overflow: hidden;
}

#slider ul {
  width: 10000%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: all .75s;
  position: relative;
  left: 0;
}

#slider ul li {
  float: left;
  height: 100%;
  position: relative;
}

#slider ul li img {
  width: 500px;
  height: 400px;
}

#slider #prev, #slider #next {
  text-align: center;
  color: #fff;
  text-shadow: 0 0 20px #0009;
  border-radius: 50%;
  width: 50px;
  font-size: 2rem;
  line-height: 50px;
  text-decoration: none;
  transition: all .15s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#slider #prev {
  left: 10px;
}

#slider #next {
  right: 10px;
}

#slider #prev:hover, #slider #next:hover {
  text-shadow: 0;
  background-color: #00000080;
}

.listTpyo {
  color: #454545;
  padding-left: 0;
  font-family: Gilroy-Regular;
  font-size: 17px;
  line-height: 1.9rem;
  list-style-type: disc !important;
}

.bg-violet:hover {
  background-color: #a78bfa;
  transition: all .3s;
}

.listTpyo::marker {
  margin: 0;
  padding: 0;
}

.hire_bg {
  background: #f9f7fc;
}

.select_tech {
  background: #eeeaff;
  border-color: #0000;
}

.search_list_hover.select_tech > div span {
  color: #7d63c8;
}

.search_list_hover:hover {
  background: #f1ecff;
}

#hire_form_loader {
  border: 5px solid #f3f3f3;
  border-top: 6px solid #9c41f2;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  margin: 15px auto;
  animation: 1s linear infinite spin;
}

#hire_form_loader.display {
  visibility: visible;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

input#Client_Mobile::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input#Client_Mobile::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (width <= 768px) {
  .swiper-slide img {
    object-fit: fill !important;
  }
}

.blogsImage {
  width: 40px !important;
  height: 40px !important;
}

.blogTimeImage {
  width: 20px !important;
  height: 20px !important;
}

.blogImageMain {
  width: 100%;
  object-fit: fill !important;
}

.open-connect {
  display: block;
}

@media screen and (width >= 1024px) and (width <= 1280px) {
  .ml_lets_65 {
    margin-left: 35px !important;
  }

  .ml_lets_42 {
    margin-left: 20px !important;
  }
}

@media screen and (width >= 1281px) {
  .ml_lets_65 {
    margin-left: 65px !important;
  }

  .ml_lets_42 {
    margin-left: 42px !important;
  }
}

.contact-code option {
  background: #5c3eb3;
}
/*# sourceMappingURL=about-us.f9cec07b.css.map */
